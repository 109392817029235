import React from "react";
import TodoList from "../components/todo_list/TodoList";

function Projects() {
  return (
    <div>
      <TodoList />
    </div>
  );
}

export default Projects;
