import React from "react";

function ContactUs() {
  return (
    <div className="contactus">
      <h1>ContactUs Page</h1>
    </div>
  );
}

export default ContactUs;
